import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../utils/API';
import ImgPlaceholder from '../../assets/images/image-placeholder.svg';
import isEmpty from '../../utils/isEmpty';
import './categorie-module.style.scss';
import urlencode from '../../utils/Urlencode';

const CategorieModule = ({ image, category, handleClick }) => {

  const [img, setImg] = useState('');
  const language = useSelector(state => state.user.language);

  const getImage = () => {

    API.get(`/documents?modulepart=category&id=${category.id}`)
      .then(resp => {
        const param = resp.data[0].fullname.split('categorie')[1];
        API.get(`/documents/download?modulepart=category&original_file=${urlencode(param)}`)
          .then( res => {
            setImg(res.data.content);
          })
          .catch(err1 => console.log("Erreur téléchargement document", err1))
      }).catch(error => {
        console.error("Erreur get Cateogry document", error);
      })
  }

  useEffect(() => {
    getImage();

  }, [])


  return (
    <div onClick={handleClick} className="creperiecategorie">
      <div className="creperiecategorie__top">
        {
          isEmpty(img)
            ? <img className='img-fluid' src={ImgPlaceholder} alt={category.label} />
            : <img className='img-fluid' src={"data:image/png;base64," + img} alt={category.label} />
        }
      </div>
      <div className="creperiecategorie__bottom text-center">
        {
          isEmpty(category.multilangs)
          ? category.label
          : language === "fr"
            ? category.multilangs.fr_FR.label
            : category.multilangs.en_GB.label
        }
      </div>
    </div>
  )
}

export default CategorieModule