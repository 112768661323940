import React from 'react';
import CategorieDetails from './categorie-details.component';

const CategorieDetailsContainer = ({match}) => {

  return (
    <CategorieDetails parametre={match} />
  )
}

export default CategorieDetailsContainer;