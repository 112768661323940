import React, {useState, useEffect} from 'react';
import API from '../../utils/API';
import urlencode from '../../utils/Urlencode';
import ImgPlaceholder from '../../assets/images/image-placeholder.svg';
import isEmpty from '../../utils/isEmpty';
import './cart-dropdown-item.style.scss';

const CartDropdownItem = ({item: {id, ref, price, label, quantity}}) => {

  const [img, setImg] = useState('');

  const getProductImage = () => {
    API.get(`/documents?modulepart=produit&id=${id}`)
    .then(resp => {
      const param = resp.data[0].fullname.split('produit')[1];
      API.get(`/documents/download?modulepart=produit&original_file=${urlencode(param)}`)
        .then( res => {
          setImg(res.data.content);
        })
        .catch(err1 => console.log("Erreur téléchargement document", err1))
    }).catch(error => {
      console.error("Erreur get Cateogry document", error);
    })
}

  useEffect(() => {
    getProductImage();
}, [])

  return (
    <div className='cart-item'>
    {
      isEmpty(img)
      ?<img src={ImgPlaceholder} alt='item' />
      :<img src={`data:image/png;base64,${img}`} alt='item' />
    }
    <div className='item-details'>
      <span className='name'>{label}</span>
      <span className='price'>
        {quantity} x {price.split('.')[0]} Fcfa
      </span>
    </div>
  </div>
  )
}

export default CartDropdownItem;