import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect, useSelector, useDispatch } from "react-redux";

import Home from "./pages/Home/home.page";
import CategorieDetailsContainer from "./pages/categorie-details/categorie-details.container";
import Layout from "./components/Layout";
import './App.css';

import { selectUserAuthentication } from "./store/reducers/user/user.selectors";
import { requestAuthenticationAsync } from "./store/reducers/user/user.actions";

import routes from "./config/routes";
import Cart from "./pages/Cart/cart.component";
import translations from "./utils/translate";
import { IntlProvider } from 'react-intl';
import userActionTypes from "./store/reducers/user/user.types";

function App({ requestAuthenticationAsync, authenticated }) {

  const language = useSelector(state => state.user.language);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: userActionTypes.SETLANGUAGE,
      payload: "fr"
  });
  }, []);

  return (
    <IntlProvider
      messages={translations[language]}
      locale={language}
      defaultLocale="en"
    >

      <Layout>
        {/* <Router> */}
        <Routes>
          <Route exact path={routes.root} element={<Home />} />
          <Route exact path={routes.categorieDetails} element={<CategorieDetailsContainer />} />
          <Route exact path={routes.cart} element={<Cart />} />
        </Routes>
        {/* </Router> */}
      </Layout>
    </IntlProvider>

  );
}

const mapStateToProps = createStructuredSelector({
  authenticated: selectUserAuthentication
});

const mapDispatchToProps = (dispatch) => ({
  requestAuthenticationAsync: () => dispatch(requestAuthenticationAsync())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
