import CategoryActionTypes from "./categories.types";

const initialState = {
    categories: [],
    categorie_products: [],
    loadingCategories: null,
    loadingCategoryProducts: null,
   
};

const categoryReducer = (state = initialState, action) => {

    switch (action.type) {

        case CategoryActionTypes.GET_CATEGORY_START:
            return {
                ...state,
                loadingCategories: true,
                categories: []
            };
        case CategoryActionTypes.GET_CATEGORY_FAILURE:
            return {
                ...state,
                loadingCategories: false,
            };
        case CategoryActionTypes.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingCategories: false,
                categories: action.payload
            };

        case CategoryActionTypes.GET_CATEGORY_PRODUCTS_START:
            return {
                ...state,
                loadingCategoryProducts: true,
            };
        case CategoryActionTypes.GET_CATEGORY_PRODUCTS_FAILURE:
            return {
                ...state,
                loadingCategoryProducts: false,
                categorie_products: []
            };
        case CategoryActionTypes.GET_CATEGORY_PRODUCTS_SUCCESS:
            return {
                ...state,
                loadingCategoryProducts: false,
                categorie_products: action.payload
            };



        default:
            return state;
    }


}

export default categoryReducer;