import React, { useEffect, useState } from 'react';
import { Icon } from "@iconify/react";
import { connect, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { addItem } from '../../store/reducers/cart/cart.actions';
import urlencode from '../../utils/Urlencode';
import ImpPlaceholder from "../../assets/images/image-placeholder.svg";
import isEmpty from '../../utils/isEmpty';
import API from '../../utils/API';
import { FormattedMessage } from 'react-intl';
import './product.style.scss';

const Product = ({ item, addItem }) => {

    const [img, setImg] = useState('');
    const language = useSelector(state => state.user.language);

    const getProductImage = () => {
        API.get(`/documents?modulepart=produit&id=${item.id}`)
            .then(resp => {
                const param = resp.data[0].fullname.split('produit')[1];
                API.get(`/documents/download?modulepart=produit&original_file=${urlencode(param)}`)
                    .then(res => {
                        setImg(res.data.content);
                    })
                    .catch(err1 => console.log("Erreur téléchargement document", err1))
            }).catch(error => {
                console.error("Erreur get Cateogry document", error);
            })
    }

    useEffect(() => {
        getProductImage();
    }, [])


    // console.log('item' , item);
    const imageBaseUrl = window.env.IMAGE_BASE_URL;
    return (
        <div className="product">

            {
                isEmpty(img)
                    ? <div className="product__top" style={{ backgroundImage: `url(${ImpPlaceholder})` }}></div>
                    : <div className="product__top" style={{ backgroundImage: `url(data:image/png;base64,${img})` }}></div>
            }
            <div className="product__bottom">
                <h3>
                    {
                        isEmpty(item.multilangs)
                            ? item.label
                            : language === "fr"
                                ? item.multilangs.fr_FR.label
                                : item.multilangs.en_GB.label
                    }
                </h3>
                <div className="product__time">
                    <Icon icon="ep:clock" height="16" />&nbsp;{item.note_public}
                </div>
                <div className="product__description">
                    {
                        isEmpty(item.multilangs)
                            ? item.description.length > 50 ? `${item.description.substring(0,50)}...` : item.description
                            : language === "fr"
                                ? item.multilangs?.fr_FR.description.length > 50 ? `${item.multilangs?.fr_FR.description.substring(0,50)}...` : item.multilangs?.fr_FR.description
                                : item.multilangs?.en_GB.description.length > 50 ? `${item.multilangs?.en_GB.description.substring(0,50)}...` : item.multilangs?.en_GB.description
                    }
                </div>
                <div className="product__down">
                    <span className='product__price'>{item.price.split('.')[0]}&nbsp;FCFA</span>
                    <button className="btn btn-primary" onClick={() => addItem(item)}>
                        {<FormattedMessage id="cart.add" />}
                    </button>
                </div>
            </div>
        </div>
    )


}

const mapDispatchToProps = (dispatch) => ({
    addItem: item => dispatch(addItem(item)),
})

export default connect(null, mapDispatchToProps)(Product);