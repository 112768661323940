import CategoryActionTypes from "./categories.types";
import API from "../../../utils/API";

const getCategoryStart = () => ({
    type: CategoryActionTypes.GET_CATEGORY_START,
});

const getCategorySuccess = (data) => ({
    type: CategoryActionTypes.GET_CATEGORY_SUCCESS,
    payload: data
});
const getCategoryFailure = () => ({
    type: CategoryActionTypes.GET_CATEGORY_FAILURE,
});

const getCategoryProductsStart = () => ({
    type: CategoryActionTypes.GET_CATEGORY_PRODUCTS_START,
});


const getCategoryProductsSuccess = (data) => ({
    type: CategoryActionTypes.GET_CATEGORY_PRODUCTS_SUCCESS,
    payload: data
    
});
const getCategoryProductsFailure = () => ({
    type: CategoryActionTypes.GET_CATEGORY_PRODUCTS_FAILURE,
});

/**
 * Fonction qui permet de récupérer les catégories de produits
 * @returns 
 */
export const getCategoriesAsync = () => dispatch => {

    dispatch(getCategoryStart());

    return new Promise((resolve, reject) => {

        // API.get(`/categories?identity=${window.env.IDENTITY}&confirmation_token=${window.env.CONFIRMATION_TOKEN}`)
        API.get(`/categories?sortfield=t.rowid&sortorder=ASC&limit=100`)
            .then(resp => {
                dispatch(getCategorySuccess(resp.data));
                resolve(resp.data);
                console.log('Reponse app doli ', resp);
            }).catch(error => {
                dispatch(getCategoryFailure());
                reject(error);
            })

    })
};

/**
 * Fonction qui permet de récupérer les products d'une catégorie
 * @param  categoryId 
 * @returns 
 */
export const getCategoryProductsAsync = (categoryId) => dispatch => {

    dispatch(getCategoryProductsStart());

    return new Promise((resolve, reject) => {
        
        API.get(`/categories/${categoryId}/objects?type=product`)
            .then(resp => {
                dispatch(getCategoryProductsSuccess(resp.data));
                resolve(resp.data);
            }).catch(error => {
                dispatch(getCategoryProductsFailure());
                reject(error);
            })

    })
};

export const getCategoryImage = (id) => {
    
    API.get(`/documents?modulepart=category&id=${id}`)
      .then(resp => {
        console.log('Response category file ', resp.data);
      }).catch(error => {
        console.error(error);
      })
  }