import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from "reselect";
import { Icon } from "@iconify/react";
import logo from '../assets/images/creperie-logo.svg';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import caretDownFill from "@iconify/icons-bi/caret-down-fill";
import notificationFill from "@iconify/icons-ri/notification-fill";

import routes from "../config/routes";

import LogoBrand from "../components/UIs/LogoBrand/LogoBrand.ui";

// import { selectAuthUser } from "../../../store/reducers/auth/auth.selectors";

// import { signoutRedirect } from "../../../utils/userManager";
import { selectCartTotal } from "../store/reducers/cart/cart.selectors";
import CartItem from "./cart-item/cart-item.component";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { FormattedMessage } from 'react-intl';
import "./NavMenu.styles.scss";
import userActionTypes from "../store/reducers/user/user.types";

const NavMenu = ({ user, match, cartTotal }) => {

  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('home');
  const [visibleCart, setVisibleCart] = useState(false);
  const language = useSelector(state => state.user.language);
  const dispatch = useDispatch();
  const location = useLocation();


  const logout = (e) => {
    e.preventDefault();
    // signoutRedirect();
  };

  const handleChangeMenu = (menu, route = '') => {
    setActiveMenu(menu);

  }

  const changeLanguage = (e) => {
    dispatch({
      type: userActionTypes.SETLANGUAGE,
      payload: e.target.value
    })
  }

  useEffect(() => {

    console.log('location ', location);
    console.log('location pathname length ', location.pathname.split('/').length);
  })

  return (
    <div className="creperienav">
      <div className="creperienav__left">
        {(location.pathname.split('/').length > 2) || (location.pathname == "/cart")
          ? <Icon icon="ion:arrow-back-sharp" height="32" onClick={() => navigate(-1)} />
          : <Icon icon="openmoji:hamburger" height="64" />
        }
        <div className="creperienav__select">
          <form action="">
            <select className="form-control" name="" value={language} onChange={changeLanguage} >
              <FormattedMessage id="menu.francais">{txt => <option value="fr">{txt}</option>}</FormattedMessage>
              <FormattedMessage id="menu.anglais">{txt => <option value="en">{txt}</option>}</FormattedMessage>
            </select>
          </form>
        </div>
      </div>




      <div onClick={() => navigate('/')} className="creperienav__logo">
        <img className="img-fluid App-logo" src={logo} alt="le logo" />
      </div>

      <div onClick={() => setVisibleCart(!visibleCart)} className="creperienav__cart">

        <Icon icon="dashicons:cart" height="32" />
        <span>{cartTotal} F cfa</span>
        <Icon icon="bxs:down-arrow" height="16" />


      </div>
      <CartItem isVisible={visibleCart} btnClick={() => setVisibleCart(false)} />
    </div>
  );

};

const mapStateToProps = createStructuredSelector({
  cartTotal: selectCartTotal,
});

export default connect(mapStateToProps)(NavMenu);
// export default NavMenu;
