import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStructuredSelector } from 'reselect';
import isEmpty from '../../utils/isEmpty';
import { Icon } from '@iconify/react';
import Modal from '../../components/Modal/modal.component';
import CartActionTypes from '../../store/reducers/cart/cart.types';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import { selectCartItems, selectCartTotal, selectCartItemsCount } from '../../store/reducers/cart/cart.selectors';
import { sendOrderAsync } from '../../store/reducers/cart/cart.actions';
import { FormattedMessage } from 'react-intl';

import './cart.style.scss';
import { date } from 'yup/lib/locale';

const Cart = ({ cartItems, total, sendOrder, qteTotal }) => {

  const [localState, setLocalState] = useState({
    openModal: false,
    formValue: "",
    showError: false,
    successMsg: false
  });

  const dispatch = useDispatch();

  const testRegex = (value) => {
    const regexPattern = new RegExp("[0-9]{5,9}");
    return regexPattern.test(value);
  }

  const handleChange = (e) => {
    setLocalState({
      ...localState,
      formValue: e.target.value
    })
    console.log(e.target.value);
  }

  const notify = () => {
    toast.error('Veuillez un nombre de 5 ou 6 chiffres');
  }

  const onValidateOrder = (e) => {
    e.preventDefault();
    handleValidateCart();
  }

  const onValidateOrder_old = (e) => {
    e.preventDefault();
    if (testRegex(localState.formValue)) {
      const d = new Date();
      const month = d.getMonth() + 1 > 10 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
      const data = {
        arrive_time: d.getHours() + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()),
        arrive_date: d.getDate() + '-' + month + '-' + d.getFullYear(),
        montant_total: total,
        totalQ: qteTotal,
        secteur_id: 2,
        client_id: window.env.IDENTITY,
        employee_pass: localState.formValue,
        packages: [],
        products: cartItems.map(item => ({
          montant: item.price,
          product_id: item.id,
          name: item.name,
          quantity: item.quantity
        }))
      }



      sendOrder(data).then(resp => {
        setLocalState({
          ...localState,
          openModal: false,
          formValue: "",
          successMsg: true
        })
        // console.log("resultat API ", resp.data.data.message);
        

      })
        .catch(error => {
          toast.error(error);
        })


    } else {
      notify();
      console.log('erreur')
      setLocalState({
        ...localState,
        formValue: "",
        showError: true
      });
    }
  }

  const handleValidateCart = () => {
    navigate('/');
    dispatch({
      type: CartActionTypes.EMPTY_CART
    })
  }

  const closeModal = () => {
    setLocalState({
      ...localState,
      openModal: false
    })
  }
  const openModal = () => {
    setLocalState({
      ...localState,
      openModal: true
    })
  }

  const openSweetAlert = () => {
    setLocalState({
      ...localState,
      successMsg: true
    })
  }

  const navigate = useNavigate();

  return (
    <div className='checkout-page'>
      <div className="checkout-container">
        <div className='checkout-header'>
          <div className='header-block'>
          <span>{<FormattedMessage id="cart.miniature" />}</span>
        </div>
          <div className='header-block'>
            {/* <span>Description</span> */}
            <span>{<FormattedMessage id="cart.produit" />}</span>
          </div>
          <div className='header-block'>
            <span>{<FormattedMessage id="cart.qte" />}</span>
          </div>
          <div className='header-block'>
            <span>{<FormattedMessage id="cart.prix" />}</span>
          </div>
          <div className='header-block'>
            <span>{<FormattedMessage id="cart.remove" />}</span>
          </div>
        </div>
        {
          isEmpty(cartItems)
            ? <div className="empty-cart">{<FormattedMessage id="cart.empty" />}</div>
            : cartItems.map(cartItem => (
              <CheckoutItem key={cartItem.id} cartItem={cartItem} />
            ))

        }
        <div className='total'>TOTAL: {total} Fcfa</div>
      </div>
      <div className="checkout-btngroup">
        <button className='btn btn-info'
          onClick={() => navigate(-1)}
        >
          <Icon icon="bi:arrow-left" color="white" height="24" />
          {<FormattedMessage id="cart.continuer-button" />}
        </button>

        {
          isEmpty(cartItems)
            ? null
            :
            <button
              className='btn btn-primary'
              // onClick={openModal}
              onClick={openSweetAlert}
            >
              <Icon icon="akar-icons:check" color="white" height="24" />
              {<FormattedMessage id="cart.validate-button" />}
            </button>
        }
      </div>
      <Modal
        visible={localState.openModal}
        onClose={closeModal}
      >
        <div className="cart__confirmation">
          <form action="">
            <input
              type="text"
              className="form-control"
              value={localState.formValue}
              onChange={handleChange}
              placeholder="Ex: 564689"
            />

            <button
              type="submit"
              className="btn btn-lg btn-success"
              onClick={onValidateOrder}
            >
              Valider
            </button>
            <ToastContainer />
          </form>
        </div>
      </Modal>
      {
        localState.successMsg && (
          <SweetAlert
          success
          title="Information !"
          onConfirm={handleValidateCart}
        >
          {<FormattedMessage id="cart.order" />}
        </SweetAlert>
        )
      }
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  qteTotal: selectCartItemsCount
});

const mapDispatchToProps = dispatch => ({
  sendOrder: (data) => dispatch(sendOrderAsync(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart);