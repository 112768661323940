import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearItemFromCart } from '../../store/reducers/cart/cart.actions';
import { Icon } from '@iconify/react';
import API from '../../utils/API';
import urlencode from '../../utils/Urlencode';
import './checkout-item.style.scss';
import isEmpty from '../../utils/isEmpty';
import ImgPlaceholder from '../../assets/images/image-placeholder.svg'

const CheckoutItem = ({ cartItem, clearItem }) => {

  const {id, label, image, price, quantity } = cartItem;
  console.log("cartitem info", cartItem)

  const [img, setImg] = useState('');

  const getProductImage = () => {
    API.get(`/documents?modulepart=produit&id=${id}`)
      .then(resp => {
        const param = resp.data[0].fullname.split('produit')[1];
        API.get(`/documents/download?modulepart=produit&original_file=${urlencode(param)}`)
          .then(res => {
            setImg(res.data.content);
          })
          .catch(err1 => console.log("Erreur téléchargement document", err1))
      }).catch(error => {
        console.error("Erreur get Cateogry document", error);
      })
  }

  useEffect(() => {
    getProductImage();
  }, [])


  const imageBaseUrl = window.env.IMAGE_BASE_URL;

  return (
    <div className='checkout-item'>
      <div className='image-container'>
        {
          isEmpty(img)
          ?<img src={ImgPlaceholder} alt='item' />
          :<img src={`data:image/png;base64,${img}`} alt='item' />
        }
      </div>
      <span className='name'>{label}</span>
      <span className='quantity'>{quantity}</span>
      <span className='price'>{price.split('.')[0]}</span>
      <div className='remove-button' onClick={() => clearItem(cartItem)}>
        <Icon icon="bx:trash" color="red" height="32" />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  clearItem: item => dispatch(clearItemFromCart(item))
});

export default connect(
  null,
  mapDispatchToProps
)(CheckoutItem);