const CartActionTypes = {
    TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
    ADD_ITEM: 'ADD_ITEM',
    CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
    SEND_ORDER_START:"SEND_ORDER_START",
    SEND_ORDER_SUCCESS:"SEND_ORDER_SUCCESS",
    SEND_ORDER_FAIL:"SEND_ORDER_FAIL",
    EMPTY_CART:"EMPTY_CART"
};

export default CartActionTypes;