import React, { Children } from 'react';
import './modal.style.scss';

const Modal = ({ visible, onClose,  children }) => {
    return (
        <div className={`lacreperie__modal ${visible ? "open" : "hide"}`}>
            <div className="lacreperie__modal-top">
            <span onClick={onClose}>X</span>
                <h5>Veuillez saisir votre code <br /> pour valider la commande</h5>
                <div className="lacreperie__modal-children">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;