import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import todosReducer from "./todos/todos.reducer";
import userReducer from "./user/user.reducer";
import categoryReducer from "./categories/categories.reducer";
import cartReducer from "./cart/cart.reducer";

export const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "todo",
        "user",
        // "category",
        // "cart"
    ]
};

const topReducer = combineReducers({
    todo: todosReducer,
    user: userReducer,
    category:categoryReducer,
    cart:cartReducer,
});

const rootReducer = (state, action) => {
    return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);