import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getCategoryProductsAsync } from '../../store/reducers/categories/categories.actions';
import Product from '../../components/product/product.component';
import { FormattedMessage } from 'react-intl';
import './categorie-details.style.scss';
import CatalogMagic from '../../components/Loaders/catalog-magic/catalog-magic';

const CategorieDetails = ({ getCategoryProducts }) => {


    const location = useLocation();
    const navigate = useNavigate();
    const language = useSelector(state => state.user.language);
    const categorieProducts = useSelector(state => state.category.categorie_products);
    const loadingCatProducts = useSelector(state => state.category.loadingCategoryProducts);


    useEffect(() => {
        getCategoryProducts(location.state.categoryId);
    }, [location.state.categoryId]);

    return (
        <div className="categoriedetails">
            <div className="container">
                <h1> <span>{
                    language === "fr"
                        ? location.state.categoryName_fr
                        : location.state.categoryName_en
                }</span></h1>
                <div className="categoriedetails__elements">
                    <div className="row">
                        {
                            categorieProducts.length < 1 && loadingCatProducts === true
                                ? <CatalogMagic />
                                : categorieProducts.length < 1 && loadingCatProducts === false
                                    ? <SweetAlert
                                        warning
                                        title="Information !"
                                        onConfirm={() => navigate(-1)}
                                    >
                                        {<FormattedMessage id="category.empty" />}
                                    </SweetAlert>
                                    : categorieProducts.map(pdt =>
                                        <div key={pdt.id} className="">
                                            <Product
                                                item={pdt}
                                            />
                                        </div>
                                    )


                        }

                    </div>
                </div>
            </div>
        </div>
    )
}



const mapDispatchToProps = (dispatch) => ({
    getCategoryProducts: (id) => dispatch(getCategoryProductsAsync(id)),
})

export default connect(null, mapDispatchToProps)(CategorieDetails);