//Définir les routes de l'application
const baseRoute = "";

const routes = {
  root: baseRoute + "/",
  categorieDetails: baseRoute + "/categorie/:id",
  cart: baseRoute + "/cart",
};

export default routes;
