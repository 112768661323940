import CartActionTypes from './cart.types';
import API from "../../../utils/API";

export const toggleCartHidden = () => ({
  type: CartActionTypes.TOGGLE_CART_HIDDEN
});

export const addItem = item => ({
  type: CartActionTypes.ADD_ITEM,
  payload: item
});

export const clearItemFromCart = item => ({
  type: CartActionTypes.CLEAR_ITEM_FROM_CART,
  payload: item
});

const sendOrderSuccess = () => ({
  type: CartActionTypes.SEND_ORDER_SUCCESS
});
const sendOrderFailure = () => ({
  type: CartActionTypes.SEND_ORDER_FAIL
});
const sendOrderStart = () => ({
  type: CartActionTypes.SEND_ORDER_START
});

export const sendOrderAsync = (data) => dispatch => {
  dispatch(sendOrderStart());

  return new Promise((resolve, reject) => {

    API.post(`/commandes?identity=${window.env.IDENTITY}&confirmation_token=${window.env.CONFIRMATION_TOKEN}`, data)
      .then(resp => {
        dispatch(sendOrderSuccess());
        resolve(resp);
      }).catch(error => {
        dispatch(sendOrderFailure());
        reject(error);
      })
  });



}