export default {
    fr:{
        "header.welcome": "Bienvenue à la crêperie",
        "cart.empty":"Votre panier est vide",
        "cart.see":"Voir le panier",
        "cart.add":"Ajouter au panier",
        "cart.remove":"Retirer",
        "cart.miniature":"Miniature",
        "cart.produit":"Produit",
        "cart.prix":"Prix",
        "cart.qte":"Quantité",
        "cart.continuer-button":"Continuer les achats",
        "cart.validate-button":"Valider la commande",
        "menu.francais": "Français",
        "menu.anglais": "Anglais",
        "cart.order":"La commande a été bien enregistrée avec succès !!",
        "category.empty":"Il n\'y a pas de produits dans cette catégorie ! "
    },
    en:{
        "header.welcome": "Welcome to the Crêperie",
        "cart.empty":"Your cart is empty",
        "cart.see":"See details",
        "cart.add":"Add to cart",
        "cart.remove":"Remove",
        "cart.miniature":"Thumbnail",
        "cart.produit":"Product",
        "cart.prix":"Prcex",
        "cart.qte":"Quantity",
        "cart.continuer-button":"Continue shopping",
        "cart.validate-button":"Validate the order",
        "menu.francais": "French",
        "menu.anglais": "English",
        "cart.order":"The order was well registered",
        "category.empty":"There are no products in this category!"
    }
}