import React from 'react';
import { connect, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import routes from '../../config/routes';
import CartDropdownItem from './cart-dropdown-item';
import { FormattedMessage } from 'react-intl';
import './cart-item.style.scss';

const CartItem = ({isVisible, btnClick}) => {

    const navigate = useNavigate();
    const cartItems = useSelector(state => state.cart.cartItems);

    const handleClick = () => {
        btnClick();
        navigate(`${routes.cart}`);
    }

  return (
    <div className={`creperiecart ${isVisible ? "show" : "hide"}`}>

        <div className="creperiecart__items">
            {
                cartItems.length ? 
                (
                    cartItems.map(cartItem => 
                        <CartDropdownItem key={cartItem.id} item={cartItem} />
                    )
                )
                :(
                    <span className="empty-class">
                        {<FormattedMessage id="cart.empty" />}
                    </span>
                )
            }
        </div>

        <div className="creperiecart__action">
            <button onClick={handleClick}  className="btn btn-primary" type="button">
            {<FormattedMessage id="cart.see" />}
            </button>
        </div>

    </div>
  )
}



export default CartItem;