import CartActionTypes from './cart.types';
import { addItemToCart } from './cart.utils';

const INITIAL_STATE = {
    hidden: true,
    cartItems: [],
    sendingOrder: false
};

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.TOGGLE_CART_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            };
        case CartActionTypes.ADD_ITEM:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            };
        case CartActionTypes.CLEAR_ITEM_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(
                    cartItem => cartItem.id !== action.payload.id
                )
            };
        case CartActionTypes.SEND_ORDER_START:
            return {
                ...state,
                sendingOrder: true,
            };
        case CartActionTypes.SEND_ORDER_SUCCESS:
            return {
                ...state,
                sendingOrder: false,
            };
        case CartActionTypes.SEND_ORDER_FAIL:
            return {
                ...state,
                sendingOrder: false,
            };
        case CartActionTypes.EMPTY_CART:
            return {
                ...state,
                cartItems:[],
            };
        default:
            return state;
    }
};

export default cartReducer;