const CategoryActionTypes = {
    GET_CATEGORY_START: "GET_CATEGORY_START",
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
    GET_CATEGORY_PRODUCTS_START: "GET_CATEGORY_PRODUCTS_START",
    GET_CATEGORY_PRODUCTS_SUCCESS: "GET_CATEGORY_PRODUCTS_SUCCESS",
    GET_CATEGORY_PRODUCTS_FAILURE: "GET_CATEGORY_PRODUCTS_FAILURE",
    
};

export default CategoryActionTypes;