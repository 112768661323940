import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import {useSelector} from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './layout.scss';

const Layout = ({children}) => {

  const location = useLocation();

  const categories = useSelector(state => state.category.categories);
  const categorieProducts = useSelector(state => state.category.categorie_products);


  return (
    <div className={`creperie__layout ${categories.length < 5 || categorieProducts.length < 3 ? "creperie__layout-vh" : null} `}>
      <NavMenu />
      {/* <div style={{marginLeft: '4%', marginRight: '4%'}}> */}
      <div>
        {children}
      </div>
    </div>
  );

}

export default Layout;
