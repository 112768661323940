import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import isEmpty from '../../utils/isEmpty';
import { useQuery } from "react-query";
import plusIcon from "@iconify/icons-akar-icons/plus";
import CategorieModule from '../../components/categorie-module/categorie-module.component';
import API from '../../utils/API';
import { getCategoriesAsync, getCategoryImage } from '../../store/reducers/categories/categories.actions';
import CatalogMagic from '../../components/Loaders/catalog-magic/catalog-magic';
import './home.styles.scss';
import { FormattedMessage } from 'react-intl';
import { isFocusable } from '@testing-library/user-event/dist/utils';


const Home = ({
    getCategories,
    // getCategoryImage,
}) => {


    const [loadingPage, setLoadingPage] = useState(false);
    const navigate = useNavigate();
    const language = useSelector(state => state.user.language);
    const imgBaseURL = window.env.IMAGE_BASE_URL;

    

    const fetchCategories = async () => {
        // console.log('KOBE ', kobe);
        const { data } = await API.get(`/categories?sortfield=t.rowid&sortorder=ASC&limit=100&type=product`)
       
        return data;
    }

    useEffect(() =>{
        getCategories();
    },[])

    const { isLoading, data, status, isError, error, isFetching } = useQuery(
        'categories',
        fetchCategories,
        {
            keepPreviousData: true,
        }
    );

    if (status === "loading") {
        return <div className="creperiehome">
            <div className="container">
                <h1 className='text-center'>{<FormattedMessage id="header.welcome" />}</h1>
                <div className="row">
                    <CatalogMagic />
                </div>
            </div>
        </div>
    }

   

    return (
        <div className="creperiehome">
            <div className="container">
                <h1 className='text-center'>{<FormattedMessage id="header.welcome" />}</h1>
                <div className="row">
                    {
                        data.map(el =>
                            <div key={el.id} className="col-6 mt-3">
                                <CategorieModule
                                    // image={`${imgBaseURL}categories/${el.image}`}
                                    // image={() => getCatImg(el.id)}
                                    category={el}
                                    handleClick={() => navigate(`/categorie/${el.id}`, {
                                        state: {
                                            categoryId: el.id,
                                            categoryName_fr: isEmpty(el.multilangs)
                                            ?el.label: el.multilangs.fr_FR.label ,
                                            categoryName_en: isEmpty(el.multilangs)
                                            ?el.label: el.multilangs.en_GB.label
                                        }
                                    })
                                    }
                                />
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    // todos: selectTodosList,
});

const mapDispatchToProps = (dispatch) => ({
    getCategories: () => dispatch(getCategoriesAsync()),
    getCategoryImage: (id) => dispatch(getCategoryImage(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);